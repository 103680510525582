<!-- Elements for placing inside a QuestionAccordion. -->
<script>
  import { slide } from 'svelte/transition'

  import { clickOrEnterHandler } from '@/util/svelte.js'

  export let title
  export let expanded = false
  export let darkTheme = true
  export let webflow = false
  export let verticalSpacing = 3
  export let titleSize = '2xl'
  export let titleWeightClass = 'font-medium'
  export let additionTitleClass = ''

  const titleVariants = {
    xs: 'text-xs',
    sm: 'text-sm',
    base: 'text-base',
    md: 'text-md',
    lg: 'text-lg',
    xl: 'text-x;',
    '2xl': 'text-2xl',
  }

  const titleClass = titleVariants[titleSize]
</script>

<div class="text-left" class:text-white={darkTheme}>
  <div
    class="header cursor-pointer flex items-center {webflow
      ? 'my-2.5'
      : `py-${verticalSpacing}`}"
    use:clickOrEnterHandler={() => (expanded = !expanded)}
  >
    <span
      class="text-2xl {webflow ? 'text-white' : 'text-green'} font-bold {webflow
        ? 'mr-1'
        : 'mr-2'}"
    >
      {#if webflow}
        +
      {:else}
        {expanded ? '-' : '+'}
      {/if}
    </span>
    <span
      class="question leading-tight {titleWeightClass} {titleClass} {additionTitleClass} {webflow &&
        'font-bold'}">{title}</span
    >
  </div>
  {#if expanded}
    <div class="answer" transition:slide>
      <div class="answer-content">
        <slot />
      </div>
    </div>
  {/if}
</div>
