<script>
  import Section from './widgets/Section.svelte'

  import businessInsiderUrl from '../../images/landing-page/business-insider-transparent.png'
  import foxNewsUrl from '../../images/landing-page/fox-news.png'
  import donutUrl from '../../images/landing-page/donut-logo-dark.png'
  import mashableUrl from '../../images/landing-page/mashable-logo-dark.png'
  import engadgetUrl from '../../images/landing-page/engadget.png'

  const asSeenOnLogos = [
    { url: businessInsiderUrl, altText: 'Business Insider' },
    { url: foxNewsUrl, altText: 'Fox News' },
    { url: mashableUrl, altText: 'Mashable' },
    { url: donutUrl, altText: 'Donut' },
    { url: engadgetUrl, altText: 'Engadget' },
  ]
</script>

<style>
  .as-seen-on {
    display: grid;
    gap: 30px;
    grid-template: '.Area'/1fr 0.5fr 2fr 1fr 1fr;
    align-items: center;
  }

  .as-seen-on img {
    height: auto;
    width: 100%;
    filter: grayscale();
  }
</style>

<Section verticalPadding="short">
  <slot name="header" />

  <div class="as-seen-on">
    {#each asSeenOnLogos as logo}
      <img src={logo.url} alt={logo.altText} />
    {/each}
  </div>

  <slot name="cta" />
</Section>
