<script>
  import Section from './widgets/Section.svelte'

  export let price

  function jumpToReviews() {
    document.querySelector('#live_reviews').scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    })
  }
</script>

<Section center="true" background="very-light-grey">
  <h2 class="font-semibold">
    How Many Other OBD2 Sensors Can Do All That... <br /> for Only
    {price.toString()}?
    <br />
    <br />
    <span class="bigger font-black text-30">ZERO.</span>
  </h2>

  <p class="text-lg mb-8 mt-4">
    FIXD works with all gas-powered vehicles 1996 or newer and is compatible
    with all iOS and Android devices. To get FIXD into the hands of as many
    <a
      on:click={jumpToReviews}
      on:keypress={jumpToReviews}
      href="#live_reviewss"
      class="underline curosr-pointer">drivers</a
    > as possible, we're offering it at our best deal ever. So, what are you waiting
    for?
  </p>

  <slot name="cta" />
</Section>
