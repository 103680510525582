<script>
  import { twMerge } from 'tailwind-merge'
  import { colors, baseSize, sizes, baseStyle } from './base-settings.js'

  export let size = baseSize
  export let role = 'img'
  export let color = 'currentColor'

  export let ariaLabel = 'Shipping Truck Icon'

  $: fillColor = colors[color] || color
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  fill={fillColor}
  {...$$restProps}
  class={twMerge(baseStyle, sizes[size ?? 'md'], $$props.class)}
  {role}
  aria-label={ariaLabel}
  tabindex="-1"
  viewBox="0 0 30 24"
  on:click
  on:keydown
  on:keyup
  on:focus
  on:blur
  on:mouseenter
  on:mouseleave
  on:mouseover
  on:mouseout
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <path
    d="m29.25 16.5h-.75v-5.0672c0-.5953-.2391-1.1672-.6609-1.58905l-4.6829-4.68281c-.4218-.42188-.9937-.66094-1.589-.66094h-2.0672v-2.25c0-1.24219-1.0078-2.25-2.25-2.25h-12c-1.24219 0-2.25 1.00781-2.25 2.25v2.25h-2.625c-.20625 0-.375.16875-.375.375v.75c0 .20625.16875.375.375.375h12.75c.2062 0 .375.16875.375.375v.75c0 .20625-.1688.375-.375.375h-11.25c-.20625 0-.375.16875-.375.375v.75c0 .20625.16875.375.375.375h9.75c.2062 0 .375.16875.375.375v.75c0 .2063-.1688.375-.375.375h-11.25c-.20625 0-.375.1687-.375.375v.75c0 .2063.16875.375.375.375h9.75c.2062 0 .375.1687.375.375v.75c0 .2063-.1688.375-.375.375h-7.125v6c0 2.4844 2.01562 4.5 4.5 4.5 2.48437 0 4.5-2.0156 4.5-4.5h6c0 2.4844 2.0156 4.5 4.5 4.5s4.5-2.0156 4.5-4.5h2.25c.4125 0 .75-.3375.75-.75v-1.5c0-.4125-.3375-.75-.75-.75zm-21.75 5.25c-1.24219 0-2.25-1.0078-2.25-2.25s1.00781-2.25 2.25-2.25 2.25 1.0078 2.25 2.25-1.00781 2.25-2.25 2.25zm15 0c-1.2422 0-2.25-1.0078-2.25-2.25s1.0078-2.25 2.25-2.25 2.25 1.0078 2.25 2.25-1.0078 2.25-2.25 2.25zm3.75-9.75h-6.75v-5.25h2.0672l4.6828 4.6828z"
    fill={fillColor}
  />
</svg>
