<script>
  import { twMerge } from 'tailwind-merge'
  import { colors, baseSize, sizes, baseStyle } from './base-settings.js'

  export let size = baseSize
  export let role = 'img'
  export let color = 'currentColor'

  export let ariaLabel = 'star filled round'

  $: fillColor = colors[color] || color
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  fill={fillColor}
  {...$$restProps}
  class={twMerge(baseStyle, sizes[size ?? 'md'], $$props.class)}
  {role}
  aria-label={ariaLabel}
  tabindex="-1"
  viewBox="0 0 20 20"
  on:click
  on:keydown
  on:keyup
  on:focus
  on:blur
  on:mouseenter
  on:mouseleave
  on:mouseover
  on:mouseout
>
  <path
    d="M9.53834 1.10996C9.70914 0.699318 10.2909 0.699318 10.4617 1.10996L12.5278 6.07744C12.5998 6.25056 12.7626 6.36885 12.9495 6.38383L18.3123 6.81376C18.7556 6.8493 18.9354 7.40256 18.5976 7.69189L14.5117 11.1919C14.3693 11.3139 14.3071 11.5053 14.3506 11.6876L15.5989 16.9208C15.7021 17.3534 15.2315 17.6954 14.8519 17.4635L10.2606 14.6592C10.1006 14.5615 9.89938 14.5615 9.73937 14.6592L5.14806 17.4635C4.76851 17.6954 4.29788 17.3534 4.40108 16.9208L5.64939 11.6876C5.69289 11.5053 5.6307 11.3139 5.48831 11.1919L1.40241 7.69189C1.06464 7.40256 1.24441 6.8493 1.68773 6.81376L7.05054 6.38383C7.23744 6.36885 7.40024 6.25056 7.47225 6.07744L9.53834 1.10996Z"
  />
</svg>
