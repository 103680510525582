<script>
  import mainImage from '@/images/landing-page/Sensor67/mission_to_revolutionize.jpg'
  import Section from './widgets/Section.svelte'

  function jumpToBuyBox() {
    document.querySelector('.buy-box .order-button').scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
  }
</script>

<Section verticalPadding="none">
  <div class="py-[60px]">
    <div class="mb-5">
      <img
        src={mainImage}
        alt="We're on a mission to revolutionize car care"
        class="w-full"
      />
    </div>

    <div class="text-center">
      <h1 class="font-extrabold text-30 mb-4">
        We're on a Mission to Revolutionize Car Care
      </h1>
      <p class="px-2">
        <!-- svelte-ignore a11y-no-static-element-interactions -->
        FIXD is an American-owned company based in Atlanta, Georgia, founded by 3
        Georgia Tech grads on a mission to bring fairness and transparency to the
        auto repair industry. FIXD believes knowledge is power. That’s why they’re
        empowering drivers everywhere to save money and feel confident at the mechanic.
        Join the Car Care Revolution.
        <span
          class="underline cursor-pointer"
          on:click={() => jumpToBuyBox()}
          on:keypress={() => jumpToBuyBox()}>Order now risk-free!</span
        >
      </p>
    </div>
  </div>
</Section>
