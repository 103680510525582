<script>
  // NOTE: there's a more robust compatibility widget managed by FIXD Rails:
  // https://app.fixdapp.com/app/compatibility

  // This one is a recreation of the one on the LP, which
  // hard-codes a combination of MMYs and has very simple logic

  function* range(start, end, step = 1) {
    let state = start
    while (state <= end) {
      yield state
      state += step
    }
  }

  const years = Array.from(range(1950, new Date().getFullYear() + 1)).reverse()

  const makes = [
    'Acura',
    'Alfa Romeo',
    'Aston Martin',
    'Audi',
    'Bentley',
    'BMW',
    'Buick',
    'Cadillac',
    'Chevrolet',
    'Chrysler',
    'Dodge',
    'Ferrari',
    'Fiat',
    'Ford',
    'Genesis',
    'GMC',
    'Honda',
    'Hyundai',
    'Infiniti',
    'Jaguar',
    'Jeep',
    'Karma',
    'Kia',
    'Lamborghini ',
    'Land Rover',
    'Lexus',
    'Lincoln',
    'Mazda',
    'McLaren',
    'Mercedes-Benz',
    'Mini',
    'Mitsubishi',
    'Nissan',
    'Porsche',
    'Ram',
    'Smart',
    'Subaru',
    'Tesla',
    'Toyota',
    'Volkswagen',
    'Volvo',
  ]
  const fuelTypes = ['Gas', 'Hybrid', 'Diesel', 'Electric']

  let year = null
  let make = null
  let fuelType = null

  function isCompatible(year, make, fuelType) {
    if (year === null || make === null || fuelType === null) return null
    if (year < 1996) return false
    if (fuelType === 'Diesel' && year < 2006) return false
    if (fuelType === 'Electric') return false
    return true
  }

  $: compatible = isCompatible(year, make, fuelType)
</script>

<style>
  form {
    margin: auto;
    display: flex;
    flex-flow: column nowrap;
    gap: 10px;
  }

  .results {
    font-size: var(--font-size-medium);
  }

  .compatible {
    color: var(--fixd-green);
  }
</style>

<div class="compatibility">
  <form>
    <div>
      <h4 class="text-left">Year</h4>
      <div class="form-control">
        <select bind:value={year} name="year" class="no-label">
          <option value={null}>Select One</option>
          {#each years as year}
            <option value={year}>{year}</option>
          {/each}
        </select>
        <div class="select-arrow" />
      </div>
    </div>

    <div>
      <h4 class="text-left mt-0">Make</h4>
      <div class="form-control">
        <select bind:value={make} name="make" class="no-label">
          <option value={null}>Select One</option>
          {#each makes as make}
            <option value={make}>{make}</option>
          {/each}
        </select>

        <div class="select-arrow" />
      </div>
    </div>

    <div>
      <h4 class="text-left mt-0">Fuel Type</h4>
      <div class="form-control">
        <select bind:value={fuelType} name="fuel-type" class="no-label">
          <option value={null}>Select One</option>
          {#each fuelTypes as fuelType}
            <option value={fuelType}>{fuelType}</option>
          {/each}
        </select>

        <div class="select-arrow" />
      </div>
    </div>
  </form>
  <p class="results">
    {#if compatible === null}
      <!-- Nothing -->
    {:else if compatible}
      <p class="compatible">Yes! FIXD is compatible with your vehicle.</p>
      <slot name="compatible" />
    {:else}
      <p class="not-compatible">
        We're sorry, your car is not compatible with FIXD.
      </p>
      <slot name="incompatible" />
    {/if}
  </p>
</div>
