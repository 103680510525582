<script>
  import { social } from '@/util/links'
</script>

<style>
  .social-button {
    background-color: #fff;
    box-shadow: inset 0 -2px rgb(0 0 0 / 20%);
    opacity: 0.7;
    display: inline-block;
    width: 24px;
    height: 24px;
    box-sizing: border-box;
    border-radius: 5px;
    position: relative;
    margin: 2px;
  }

  .social-button svg {
    fill: #222;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 5px;
    left: 6px;
  }
</style>

<!-- TODO: Import raw and render with {@html } -->

<div class="social-buttons">
  <!-- Facebook -->
  <a
    class="social-button"
    href={social.facebookURL}
    aria-label="Facebook"
    target="_blank"
    rel="noreferrer"
  >
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      enable-background="new 0 0 24 24"
      xml:space="preserve"
      class="eapps-social-icons-item-icon"
    >
      <g>
        <path
          fill="#3E68C0"
          d="M5.677,12.998V8.123h3.575V6.224C9.252,2.949,11.712,0,14.736,0h3.94v4.874h-3.94 c-0.432,0-0.934,0.524-0.934,1.308v1.942h4.874v4.874h-4.874V24H9.252V12.998H5.677z"
        />
      </g>
    </svg>
  </a>

  <!-- Youtube -->
  <a
    class="social-button"
    href={social.youtubeURL}
    aria-label="YouTube"
    target="_blank"
    rel="noreferrer"
  >
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      enable-background="new 0 0 24 24"
      xml:space="preserve"
      class="eapps-social-icons-item-icon"
    >
      <g>
        <path
          fill="#EE3130"
          d="M23.777,6.602c-0.205-1.75-0.625-2.261-0.921-2.494c-0.475-0.371-1.337-0.499-2.487-0.578 c-1.85-0.126-4.979-0.2-8.37-0.2c-3.391,0-6.519,0.074-8.368,0.2C2.482,3.609,1.619,3.738,1.144,4.108 C0.847,4.341,0.429,4.852,0.222,6.602c-0.296,2.522-0.296,8.273,0,10.796c0.207,1.749,0.625,2.26,0.922,2.492 c0.475,0.373,1.338,0.501,2.487,0.58c1.849,0.125,4.978,0.199,8.368,0.199c3.392,0,6.52-0.075,8.37-0.199 c1.15-0.079,2.011-0.207,2.487-0.58c0.296-0.232,0.716-0.743,0.921-2.492C24.074,14.875,24.074,9.125,23.777,6.602z M9.104,15.774 V8.225L16.344,12L9.104,15.774z"
        />
      </g>
    </svg>
  </a>

  <!-- Instagram -->
  <a
    class="social-button"
    href={social.instagramURL}
    aria-label="Instagram"
    target="_blank"
    rel="noreferrer"
  >
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      enable-background="new 0 0 24 24"
      xml:space="preserve"
      class="eapps-social-icons-item-icon"
    >
      <g>
        <circle fill="#222222" cx="18.05" cy="5.992" r="1.355" />
        <path
          fill="#222222"
          d="M12.021,5.806c-3.427,0-6.215,2.788-6.215,6.215s2.788,6.215,6.215,6.215s6.215-2.788,6.215-6.215 S15.448,5.806,12.021,5.806z M12.021,15.412c-1.87,0-3.391-1.521-3.391-3.391s1.521-3.391,3.391-3.391 c1.87,0,3.391,1.521,3.391,3.391S13.891,15.412,12.021,15.412z"
        />
        <path
          fill="#222222"
          d="M23.369,4.574c-0.357-0.919-0.846-1.669-1.539-2.362c-0.693-0.693-1.443-1.182-2.362-1.539 c-0.905-0.352-1.836-0.533-3.018-0.587c-1.153-0.053-1.536-0.065-4.43-0.065c-2.895,0-3.277,0.012-4.43,0.065 C6.409,0.14,5.478,0.321,4.574,0.673C3.655,1.03,2.904,1.519,2.212,2.212C1.519,2.904,1.03,3.655,0.673,4.573 C0.321,5.478,0.14,6.409,0.086,7.591c-0.053,1.153-0.065,1.536-0.065,4.43s0.012,3.277,0.065,4.43 c0.054,1.182,0.235,2.113,0.587,3.018c0.357,0.919,0.846,1.669,1.539,2.362c0.693,0.693,1.443,1.182,2.362,1.539 c0.905,0.352,1.836,0.533,3.018,0.587c1.153,0.053,1.536,0.065,4.43,0.065c2.895,0,3.277-0.012,4.43-0.065 c1.182-0.054,2.113-0.235,3.018-0.587c0.919-0.357,1.669-0.846,2.362-1.539c0.693-0.693,1.182-1.443,1.539-2.362 c0.352-0.905,0.533-1.836,0.587-3.018c0.053-1.153,0.065-1.536,0.065-4.43s-0.012-3.277-0.065-4.43 C23.902,6.409,23.721,5.478,23.369,4.574z M21.135,16.322c-0.05,1.105-0.239,1.715-0.398,2.123 c-0.216,0.556-0.486,0.971-0.903,1.389c-0.417,0.417-0.833,0.687-1.389,0.904c-0.408,0.159-1.018,0.347-2.123,0.397 c-1.123,0.051-1.46,0.062-4.301,0.062c-2.841,0-3.178-0.011-4.301-0.062c-1.105-0.05-1.715-0.239-2.123-0.398 c-0.556-0.216-0.971-0.486-1.389-0.903c-0.417-0.417-0.687-0.833-0.904-1.389c-0.159-0.408-0.347-1.018-0.397-2.123 c-0.051-1.123-0.062-1.46-0.062-4.301s0.011-3.178,0.062-4.301c0.05-1.105,0.239-1.715,0.398-2.123 c0.216-0.556,0.486-0.971,0.903-1.389c0.417-0.417,0.833-0.687,1.389-0.904C6.005,3.146,6.615,2.957,7.72,2.907 c1.123-0.051,1.46-0.062,4.301-0.062c2.841,0,3.178,0.011,4.302,0.062c1.105,0.05,1.715,0.239,2.123,0.398 c0.556,0.216,0.971,0.486,1.389,0.903c0.417,0.417,0.687,0.833,0.904,1.389c0.159,0.408,0.347,1.018,0.397,2.123 c0.051,1.123,0.062,1.46,0.062,4.301S21.186,15.199,21.135,16.322z"
        />
      </g>
    </svg>
  </a>

  <!-- Twitter -->
  <a
    class="social-button"
    href={social.twitterURL}
    aria-label="Twitter"
    target="_blank"
    rel="noreferrer"
  >
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      enable-background="new 0 0 24 24"
      xml:space="preserve"
      class="eapps-social-icons-item-icon"
    >
      <g>
        <path
          id="twitter-4-icon_1_"
          fill="#23ABFF"
          d="M21.535,7.063c0.311,6.923-4.852,14.642-13.99,14.642 c-2.78,0-5.368-0.815-7.545-2.213c2.611,0.308,5.217-0.415,7.287-2.038c-2.154-0.039-3.972-1.462-4.599-3.418 c0.771,0.148,1.53,0.105,2.223-0.084c-2.367-0.475-4.002-2.608-3.948-4.888c0.664,0.369,1.423,0.59,2.229,0.615 C1.001,8.215,0.38,5.32,1.67,3.108c2.428,2.978,6.055,4.938,10.145,5.143c-0.717-3.079,1.618-6.044,4.796-6.044 c1.415,0,2.694,0.598,3.592,1.554c1.121-0.221,2.174-0.631,3.126-1.195c-0.368,1.149-1.149,2.114-2.164,2.724 c0.995-0.119,1.944-0.384,2.826-0.776C23.331,5.503,22.497,6.37,21.535,7.063z"
        />
      </g>
    </svg>
  </a>

  <!-- TikTok -->
  <a
    class="social-button"
    href={social.tiktokURL}
    aria-label="TikTok"
    target="_blank"
    rel="noreferrer"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="24pt"
      height="24pt"
      viewBox="0 0 24 24"
      version="1.1"
      class="eapps-social-icons-item-icon"
    >
      <g id="surface1">
        <path
          class="tiktok-red"
          style="fill-rule:nonzero;fill:#f00044;"
          d="M 8.535156 12.441406 C 6.632812 12.441406 5.09375 13.976562 5.125 15.863281 C 5.140625 17.070312 5.808594 18.121094 6.789062 18.707031 C 6.453125 18.195312 6.257812 17.59375 6.25 16.941406 C 6.21875 15.054688 7.757812 13.519531 9.660156 13.519531 C 10.035156 13.519531 10.398438 13.578125 10.734375 13.6875 L 10.734375 9.914062 C 10.382812 9.863281 10.023438 9.835938 9.660156 9.835938 C 9.640625 9.835938 9.628906 9.835938 9.609375 9.835938 L 9.609375 12.609375 C 9.273438 12.5 8.910156 12.441406 8.535156 12.441406 Z M 8.535156 12.441406 "
        />
        <path
          class="tiktok-red"
          style="fill-rule:nonzero;fill:#f00044;"
          d="M 16.761719 1.125 L 15.757812 1.125 C 16.039062 2.535156 16.832031 3.765625 17.9375 4.597656 C 17.210938 3.636719 16.773438 2.4375 16.761719 1.125 Z M 16.761719 1.125 "
        />
        <path
          class="tiktok-red"
          style="fill-rule:nonzero;fill:#f00044;"
          d="M 22.5 6.867188 C 22.128906 6.867188 21.773438 6.828125 21.421875 6.765625 L 21.421875 9.46875 C 20.148438 9.46875 18.910156 9.21875 17.746094 8.722656 C 16.996094 8.40625 16.296875 7.996094 15.65625 7.503906 L 15.675781 15.84375 C 15.664062 17.71875 14.925781 19.476562 13.585938 20.804688 C 12.492188 21.882812 11.109375 22.570312 9.609375 22.789062 C 9.257812 22.84375 8.898438 22.871094 8.535156 22.871094 C 6.933594 22.871094 5.40625 22.351562 4.164062 21.390625 C 4.304688 21.558594 4.453125 21.722656 4.617188 21.882812 C 5.96875 23.210938 7.757812 23.949219 9.664062 23.949219 C 10.027344 23.949219 10.386719 23.921875 10.738281 23.867188 C 12.238281 23.648438 13.621094 22.960938 14.714844 21.882812 C 16.054688 20.554688 16.796875 18.796875 16.804688 16.921875 L 16.734375 8.582031 C 17.371094 9.074219 18.070312 9.488281 18.824219 9.800781 C 19.992188 10.292969 21.230469 10.546875 22.5 10.546875 "
        />
        <path
          class="tiktok-blue"
          style="fill-rule:nonzero;fill:#08fff9;fill-opacity:1;"
          d="M 4.601562 11.910156 C 5.9375 10.585938 7.714844 9.847656 9.609375 9.835938 L 9.609375 8.835938 C 9.257812 8.785156 8.898438 8.757812 8.535156 8.757812 C 6.625 8.757812 4.828125 9.492188 3.476562 10.832031 C 2.152344 12.148438 1.390625 13.949219 1.398438 15.820312 C 1.398438 17.703125 2.140625 19.472656 3.488281 20.808594 C 3.703125 21.019531 3.921875 21.214844 4.15625 21.394531 C 3.097656 20.132812 2.523438 18.558594 2.515625 16.898438 C 2.523438 15.027344 3.277344 13.226562 4.601562 11.910156 Z M 4.601562 11.910156 "
        />
        <path
          class="tiktok-blue"
          style="fill-rule:nonzero;fill:#08fff9;fill-opacity:1;"
          d="M 21.421875 6.765625 L 21.421875 5.761719 L 21.414062 5.761719 C 20.109375 5.761719 18.910156 5.328125 17.945312 4.597656 C 18.78125 5.703125 20.011719 6.492188 21.421875 6.765625 Z M 21.421875 6.765625 "
        />
        <path
          class="tiktok-blue"
          style="fill-rule:nonzero;fill:#08fff9;fill-opacity:1;"
          d="M 9.46875 20.257812 C 9.914062 20.28125 10.339844 20.222656 10.734375 20.09375 C 12.09375 19.648438 13.074219 18.382812 13.074219 16.894531 L 13.078125 11.316406 L 13.078125 1.125 L 15.757812 1.125 C 15.6875 0.773438 15.652344 0.417969 15.648438 0.046875 L 11.953125 0.046875 L 11.953125 10.234375 L 11.949219 15.8125 C 11.949219 17.300781 10.96875 18.566406 9.609375 19.011719 C 9.214844 19.144531 8.789062 19.203125 8.34375 19.175781 C 7.777344 19.144531 7.246094 18.976562 6.789062 18.703125 C 7.363281 19.59375 8.347656 20.199219 9.46875 20.257812 Z M 9.46875 20.257812 "
        />
        <path
          class="tiktok-black"
          style="fill-rule:nonzero;fill:#000;fill-opacity:1;"
          d="M 9.609375 22.789062 C 11.109375 22.570312 12.492188 21.882812 13.585938 20.804688 C 14.925781 19.476562 15.664062 17.71875 15.675781 15.84375 L 15.65625 7.503906 C 16.292969 7.996094 16.992188 8.410156 17.746094 8.722656 C 18.914062 9.214844 20.152344 9.46875 21.421875 9.46875 L 21.421875 6.765625 C 20.011719 6.492188 18.78125 5.703125 17.945312 4.597656 C 16.835938 3.765625 16.039062 2.535156 15.765625 1.125 L 13.078125 1.125 L 13.078125 11.3125 L 13.074219 16.890625 C 13.074219 18.378906 12.09375 19.644531 10.734375 20.089844 C 10.339844 20.222656 9.914062 20.28125 9.46875 20.253906 C 8.34375 20.195312 7.363281 19.589844 6.789062 18.703125 C 5.808594 18.121094 5.140625 17.066406 5.125 15.859375 C 5.09375 13.972656 6.632812 12.4375 8.535156 12.4375 C 8.910156 12.4375 9.273438 12.496094 9.609375 12.605469 L 9.609375 9.828125 C 7.714844 9.84375 5.9375 10.578125 4.601562 11.90625 C 3.277344 13.222656 2.515625 15.023438 2.523438 16.890625 C 2.523438 18.546875 3.097656 20.125 4.164062 21.382812 C 5.410156 22.34375 6.933594 22.867188 8.535156 22.867188 C 8.898438 22.871094 9.257812 22.84375 9.609375 22.789062 Z M 9.609375 22.789062 "
        />
      </g>
    </svg>
  </a>
</div>
