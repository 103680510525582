<!-- 
	A container that sets the background to a specific image,
	which allows that image to be responsive to supported
	formats and display depths. It's the equivalent of <Image>
	but for background images.

	```
	import myImage from '@/path/to/img.jpg?width=1200&format=webp;jpg&as=picture'
	
	<BackgroundImage image={myImage} />
	```

	You can specify multiple resolutions, which is good for high-density
	displays:

	```
	import myImage from '@/path/to/img.jpg?width=1200;2400&format=webp;jpg&as=picture'
	import myImageFallback from '@/path/to/img.jpg?width=1200&format=jpg'
	
	<BackgroundImage image={myImage} fallback={myImageFallback} resolutions={{'1x': 1200, '2x': 2400}} />
	```

	You can also adjust based on media queries. Unfortunately this is somewhat manual, but
	it can be really useful for high-resolution splash images that are small on mobile:

	```
	import { derived } from 'svelte/store'
	import { isMobileView } from '@/stores/mediaQuery'
	
	import myImage from '@/path/to/img.jpg?width=600;1200;2400&format=webp;jpg&as=picture'
	import myImageFallback from '@/path/to/img.jpg?width=1200&format=jpg'
	
  $: resolutions = derived(isMobileView, (mobile) =>
    mobile ? { '1x': 600, '2x': 1200, '3x': 2400 } : { '1x': 1200, '2x': 2400 },
  )
	
	<BackgroundImage image={myImage} fallback={myImageFallback} {$resolutions} />
	```

	See:
	- https://developer.mozilla.org/en-US/docs/Web/CSS/image/image-set
	- https://caniuse.com/css-image-set
 -->
<script>
  import { getDescriptorsToUrls, splitSources } from '@/components/Image.svelte'

  export let image
  export let fallback = null
  export let resolutions = {}
  export let display = true

  $: if (typeof image === 'string' && RAILS_ENV !== 'production')
    console.warn(
      `Passed an image url to BackgroundImage. For responsive loading pass an image object with the \`&as=picture\` directive.\nURL: ${image}`,
    )

  function getImageSet(image, resolutions) {
    let values = []
    for (const [type, rawsources] of Object.entries(image.sources)) {
      const sources = splitSources(rawsources)
      if (Object.keys(resolutions).length === 0) {
        values = [
          ...values,
          ...sources.map((s) => `url(${s.src}) type("image/${type}")`),
        ]
      } else {
        const descriptorsToUrls = getDescriptorsToUrls(sources)
        for (const [resolution, width] of Object.entries(resolutions)) {
          values.push(
            `url(${descriptorsToUrls.get(
              width,
            )}) ${resolution} type("image/${type}")`,
          )
        }
      }
    }
    return `image-set(${values.join(', ')})`
  }

  const encodeUrl = (url) => btoa(url.split('').reverse().join('')).slice(0, 15)

  $: fallbackSrc = `url(${
    fallback ?? (typeof image === 'string' ? image : image.img.src)
  })`
  $: imgSetSrc =
    typeof image === 'string' ? fallbackSrc : getImageSet(image, resolutions)

  // In order to get a fallback `background-image` tag to work on browsers which don't support
  // `image-set()`, we have to set both versions of `background-image`. Unfortunately we can't
  // set those using the standard Svelte `style:background-image` trick because inline styles
  // can only be set once. Instead, we add a custom style tag to the DOM and manipulate its
  // content.
  $: uniqueClassName = encodeUrl(fallbackSrc)
  $: css = `.bg-image.${uniqueClassName} {
			background-image: ${fallbackSrc};
			background-image: -webkit-${imgSetSrc};
			background-image: ${imgSetSrc};
	}`
</script>

<style>
  .bg-container {
    position: relative;
  }

  .bg-image {
    position: absolute;
    z-index: -100;
    width: 100%;
    height: 100%;
    background-position: var(--background-position, right);
    background-size: var(--background-size, cover);
  }
</style>

<div class="bg-container">
  {#if display}
    <svelte:element this={'style'}>{css}</svelte:element>
    <div class="bg-image {uniqueClassName}" {...$$restProps} />
  {/if}
  <div>
    <slot />
  </div>
</div>
