<script>
  export let showModal // boolean
  export let maxWidth = 'md'
  export let onClose = null

  let dialog // HTMLDialogElement

  $: if (dialog && showModal) dialog.showModal()

  $: if (dialog && !showModal) dialog.close()

  const maxWidthClasses = {
    sm: 'max-w-screen-sm',
    md: 'max-w-screen-md',
    lg: 'max-w-screen-lg',
    xl: 'max-w-screen-xl',
    '2xl': 'max-w-screen-2xl',
  }

  $: maxWidthClass = maxWidthClasses[maxWidth]
</script>

<style>
  .dialog::backdrop {
    background-color: black;
    opacity: 0.65;
  }
</style>

<!-- svelte-ignore a11y-click-events-have-key-events a11y-no-noninteractive-element-interactions -->
<dialog
  class="dialog rounded w-full bg-transparent {maxWidthClass}"
  bind:this={dialog}
  on:close={() => {
    onClose()
    showModal = false
  }}
  on:click|self={() => dialog.close()}
>
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <div on:click|stopPropagation>
    <slot name="header" />

    <slot />
  </div>
</dialog>
