<script>
  import { twMerge } from 'tailwind-merge'
  import { colors, baseSize, sizes, baseStyle } from './base-settings.js'

  export let size = baseSize
  export let role = 'img'
  export let color = 'currentColor'

  export let ariaLabel = 'Google Play Store'

  $: fillColor = colors[color] || color
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  fill={fillColor}
  {...$$restProps}
  class={twMerge(baseStyle, sizes[size ?? 'md'], $$props.class)}
  {role}
  aria-label={ariaLabel}
  tabindex="-1"
  viewBox="0 0 24 24"
  on:click
  on:keydown
  on:keyup
  on:focus
  on:blur
  on:mouseenter
  on:mouseleave
  on:mouseover
  on:mouseout
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <path
    fill-rule="evenodd"
    d="m14.12 12.81 2.819 2.842L5.81 21.79c-.187.097-.372.21-.748.21-.016 0-.033 0-.05-.002l.009-.008 9.099-9.18ZM4.182 2.769l.012.011.034.035 9.146 9.239-9.003 9.088-.1.102c-.405-.03-.77-.444-.77-.881V3.638c0-.405.313-.791.681-.87Zm13.675 6.265 4.14 2.286c.337.187.505.432.504.676.001.245-.167.49-.505.675l-4.21 2.327-2.916-2.945 2.987-3.019ZM5.063 2c.376 0 .56.113.748.211l11.197 6.175-2.887 2.914L4.92 2.017A.628.628 0 0 1 5.063 2Z"
    clip-rule="evenodd"
  ></path>
</svg>
