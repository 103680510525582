<script>
  import { twMerge } from 'tailwind-merge'
  import { colors, baseSize, sizes, baseStyle } from './base-settings.js'

  export let size = baseSize
  export let role = 'img'
  export let color = 'currentColor'

  export let ariaLabel = 'apple play store'

  $: fillColor = colors[color] || color
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  fill={fillColor}
  {...$$restProps}
  class={twMerge(baseStyle, sizes[size ?? 'md'], $$props.class)}
  {role}
  tabindex="-1"
  aria-label={ariaLabel}
  viewBox="0 0 24 24"
  on:click
  on:keydown
  on:keyup
  on:focus
  on:blur
  on:mouseenter
  on:mouseleave
  on:mouseover
  on:mouseout
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <path
    fill-rule="evenodd"
    d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2Zm1.258 7.056c-.08.09-.161.18-.236.275a2.098 2.098 0 0 0-.256 1.882c.098.307.288.573.45.85.25.417.502.83.75 1.247l.897 1.489.223.373.223.373c.17.276.336.555.503.832.134.232.294.479.568.572.391.158.877-.066 1.017-.444.112-.26.023-.55-.124-.778-.242-.407-.492-.81-.73-1.217.407.013.817.023 1.223-.006.41-.025.758-.392.733-.787-.011-.395-.384-.727-.792-.731a89.1 89.1 0 0 0-2.068 0 1.394 1.394 0 0 1-.122-.173c-.52-.87-1.045-1.739-1.568-2.608a287 287 0 0 1-.691-1.15ZM9.4 15.12c-.414-.515-1.173-.673-1.812-.492-.261.452-.599.873-.774 1.364-.083.413.2.85.629.968.394.127.84-.063 1.053-.388.238-.353.447-.722.675-1.081.075-.125.151-.248.229-.371Zm4.421-8.778c-.22-.298-.663-.44-1.013-.267-.388.172-.51.599-.743.91-.217-.32-.353-.741-.74-.913-.352-.17-.8-.024-1.013.282a.79.79 0 0 0 0 .848c.265.454.538.904.816 1.351-.27.44-.532.883-.8 1.323-.625 1.037-1.253 2.072-1.875 3.11-.676.005-1.353 0-2.028.002-.41-.03-.798.23-.911.606a.788.788 0 0 0 .369.825c.211.133.473.132.716.137 2.398-.004 4.796.003 7.194-.004.196-.267.147-.652.015-.943a1.195 1.195 0 0 0-1.03-.62c-.815-.002-1.629-.003-2.443 0 .727-1.186 1.437-2.38 2.16-3.569.438-.738.892-1.467 1.326-2.207a.801.801 0 0 0 0-.87Z"
    clip-rule="evenodd"
  ></path>
</svg>
