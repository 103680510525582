<script>
  import { twMerge } from 'tailwind-merge'
  import { colors, baseSize, sizes, baseStyle } from './base-settings.js'

  export let size = baseSize
  export let role = 'img'
  export let color = 'currentColor'

  export let ariaLabel = 'lightning'

  $: fillColor = colors[color] || color
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  fill={fillColor}
  {...$$restProps}
  class={twMerge(baseStyle, sizes[size ?? 'md'], $$props.class)}
  {role}
  aria-label={ariaLabel}
  viewBox="0 0 14 16"
  on:click
  on:keydown
  on:keyup
  on:focus
  on:blur
  on:mouseenter
  on:mouseleave
  on:mouseover
  on:mouseout
>
  <path
    d="M4.2682 15.9167C4.37525 15.9728 4.49103 16 4.6057 16C4.81482 16 5.02034 15.9098 5.16231 15.7411L12.9199 6.52905C13.1021 6.31266 13.1423 6.01031 13.023 5.75382C12.9037 5.49734 12.6465 5.33332 12.3636 5.33332H8.35512L11.0378 1.11772C11.1803 0.893767 11.1894 0.609986 11.0617 0.377308C10.934 0.144581 10.6897 0 10.4242 0H6.06058C5.80104 0 5.56119 0.138278 5.4312 0.36286L0.0978848 9.57495C-0.0323935 9.79997 -0.032636 10.0774 0.0972061 10.3026C0.227097 10.5279 0.46729 10.6666 0.727265 10.6666H5.10839L3.90437 15.0813C3.81458 15.4107 3.9659 15.7582 4.2682 15.9167Z"
  />
</svg>
