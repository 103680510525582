import { readable } from 'svelte/store'

export const createMediaQueryStore = (query) => {
  const matcher = window.matchMedia(query)

  return readable(matcher.matches, (set) => {
    // seems Safari iOS doesn't implement MediaQueryList.addEventListener.
    // that's okay because it won't ever change on Safari iOS.
    matcher.addEventListener &&
      matcher.addEventListener('change', (e) => set(e.matches))
  })
}

export const createMobileViewStore = (width) => {
  return createMediaQueryStore(`only screen and (max-width: ${width}px)`)
}

// This is our standard mobile/desktop media query
export const isMobileView = createMobileViewStore(1000)
