<script>
  import { createMediaQueryStore } from '@/stores/mediaQuery'

  import Image from '@/components/Image.svelte'
  import BackgroundImage from '@/components/BackgroundImage.svelte'

  // TODO: can only be used on 67% off pages
  import phoneImg from '@/images/landing-page/Sensor67/white_phone_and_sensor_transparent.png?width=500&as=picture'
  import iconCarsURL from '@/images/landing-page/Sensor67/Cars.png?w=70'
  import iconCelURL from '@/images/landing-page/Sensor67/OrangeEngine.png?w=70'
  import iconCheckURL from '@/images/landing-page/Sensor67/GreenCheckMark.png?w=70'
  import iconWirelessURL from '@/images/landing-page/Sensor67/wireless.png?w=70'
  import iconWrenchesURL from '@/images/landing-page/Sensor67/RedWrench.png?w=70'
  import iconDetailsURL from '@/images/landing-page/Sensor67/Magnify.png?w=70'

  const isMobileView = createMediaQueryStore(
    'only screen and (max-width: 500px)',
  )

  const benefits = [
    {
      icon: iconCelURL,
      title: 'Check Engine',
      body: `Check engine light? FIXD instantly tells you in-depth what's wrong in plain, simple terms right on your smartphone.`,
    },
    {
      icon: iconWrenchesURL,
      title: 'Issue Severity',
      body: `Find out how serious your issue is so you know whether or not it's safe to continue driving. You can even turn off the light if it's nothing serious!`,
    },
    {
      icon: iconCheckURL,
      title: 'Maintenance Tracker',
      body: `Our algorithm tracks your mileage and automatically notifies you when it's time for routine maintenance so you can avoid costly repairs and breakdowns.`,
    },
    {
      icon: iconDetailsURL,
      title: 'Detailed Diagnostics',
      body: `Unlike other OBD2 sensors, FIXD provides detailed, easy-to-understand information to help you feel confident at the repair shop or even complete repairs yourself.`,
    },
    {
      icon: iconCarsURL,
      title: 'Multi-Vehicle Control',
      body: `Monitor all your vehicles from one account, making it easy to schedule services for family members even if you're not there!`,
    },
    {
      icon: iconWirelessURL,
      title: 'Avoid Costly Repairs',
      body: `FIXD automatically syncs to your smartphone via Bluetooth while you're driving so that you can continuously monitor your car's health and quickly resolve any issues.`,
    },
  ]
</script>

<style>
  .group {
    display: flex;
    flex-flow: column nowrap;
    flex-basis: 100%;
  }
</style>

<div
  class="benefits text-center flex flex-col md:flex-row flex-nowrap items-center"
>
  <div class="group">
    {#each benefits.slice(0, 3) as benefit}
      <div class="benefit mb-4">
        <div
          class="flex flex-col justify-center md:justify-start md:flex-row flex-nowrap items-center gap-1 text-center mb-4"
        >
          <img src={benefit.icon} alt={benefit.title} class="max-w-[70px]" />
          <h3 class="text-xl m-0">{benefit.title}</h3>
        </div>
        <p class="text-center md:text-left mt-2">{benefit.body}</p>
      </div>
    {/each}
  </div>

  <div class="group">
    <BackgroundImage
      image={phoneImg}
      resolutions={{ '1x': 500, '2x': 1000 }}
      display={!$isMobileView}
    >
      <Image
        image={phoneImg}
        alt="The FIXD app and sensor"
        --width="100%"
        media={{ default: { '1x': 500, '2x': 1000 } }}
      />
    </BackgroundImage>
  </div>

  <div class="group">
    {#each benefits.slice(3, 6) as benefit}
      <div class="benefit mb-4">
        <div
          class="flex flex-col justify-center md:flex-row flex-nowrap items-center gap-1 text-center mb-4"
        >
          <img src={benefit.icon} alt={benefit.title} class="max-w-[70px]" />
          <h3 class="text-xl m-0">{benefit.title}</h3>
        </div>
        <p class="text-center md:text-left mt-2">{benefit.body}</p>
      </div>
    {/each}
  </div>
</div>
