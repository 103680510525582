<script>
  import { twMerge } from 'tailwind-merge'
  import { colors, baseSize, sizes, baseStyle } from './base-settings.js'

  export let size = baseSize
  export let role = 'img'
  export let color = 'currentColor'

  export let ariaLabel = 'Shipping Truck Icon'

  $: fillColor = colors[color] || color
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  fill={fillColor}
  {...$$restProps}
  class={twMerge(baseStyle, sizes[size ?? 'md'], $$props.class)}
  {role}
  aria-label={ariaLabel}
  tabindex="-1"
  viewBox="0 0 24 24"
  on:click
  on:keydown
  on:keyup
  on:focus
  on:blur
  on:mouseenter
  on:mouseleave
  on:mouseover
  on:mouseout
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <path
    d="m21.4979 11.9963 2.1555-2.10965c.6426-.6083.343-1.68933-.4998-1.89089l-2.9368-.74953.8279-2.90695c.2339-.83615-.5545-1.62482-1.3904-1.39082l-2.9059.82814-.7493-2.937705c-.1983-.82963978-1.2914-1.133061-1.8902-.500062l-2.1089 2.172277-2.10887-2.17223c-.59203-.625828-1.6897-.33909359-1.89023.500062l-.74925 2.937698-2.90602-.82814c-.8361-.23409-1.62426.55486-1.39035 1.39083l.82785 2.90695-2.936711.74953c-.84318623.20161-1.142201 1.28283-.499921 1.89084l2.155542 2.10965-2.155542 2.1095c-.642562.6083-.34293701 1.6893.499875 1.8909l2.936717.7495-.82786 2.907c-.23395.8361.55448 1.6248 1.39036 1.3908l2.90596-.8281.74925 2.9377c.20808.8707 1.29825 1.1258 1.89023.5l2.10897-2.1563 2.1088 2.1563c.586.6323 1.6869.3509 1.8903-.5l.7492-2.9377 2.906.8281c.8361.2341 1.6243-.5548 1.3903-1.3908l-.8278-2.907 2.9367-.7495c.8431-.2016 1.1422-1.2828.4999-1.8909z"
    fill={fillColor}
  />
</svg>
