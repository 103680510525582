<script>
  export let text = ''

  let isTruncated = true

  const toggleTruncate = () => {
    isTruncated = !isTruncated
  }
</script>

<style>
  .read-more-button {
    text-decoration: none;
    font-weight: 400;
  }
</style>

<div class="text-container overflow-hidden" class:line-clamp-3={isTruncated}>
  <p>{text}</p>
</div>
{#if text.length > 300}
  <button class="secondary read-more-button" on:click={toggleTruncate}>
    {isTruncated ? 'Read more' : 'Hide'}
  </button>
{/if}
