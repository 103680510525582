<script>
  import Section from './widgets/Section.svelte'
  import CompatibilityWidget from './widgets/CompatibilityWidget.svelte'
</script>

<Section center="true">
  <slot name="header">
    <h1 class="font-medium mb-2">Wondering If Your Car Is Compatible?</h1>
  </slot>

  <slot name="content" />

  <CompatibilityWidget>
    <div slot="compatible">
      <slot name="cta" />
    </div>
  </CompatibilityWidget>
</Section>
