<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'

  import foundersImg from '@/images/landing-page/3-tech-grads-illustration1.png?width=200&as=picture'
  import Image from '@/components/Image.svelte'
</script>

<Section center="true" background="very-light-grey">
  <div class="about">
    <Image image={foundersImg} alt="The founders" />
    <slot name="header">
      <h1 class="font-medium text-30 my-0">About FIXD</h1>
    </slot>

    <p class="mx-auto w-9/12 mt-2">
      FIXD is headquartered in Atlanta, Georgia, and proud to be an
      American-owned company. Founded by John Gattuso, Julian Knight, and
      Frederick Grimm, 3 Georgia Tech grads who were sick of seeing friends and
      family get ripped off at the repair shop, FIXD has sold over 2 million
      sensors to drivers across America. They want to empower car owners
      everywhere to save time, save money, and simplify car care.
    </p>
    <slot name="cta" />
  </div>
</Section>
