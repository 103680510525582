<script>
  import oneThumbnail from '@/images/landing-page/Sensor67/youtube/1.jpeg'
  import twoThumbnail from '@/images/landing-page/Sensor67/youtube/2.jpg'
  import threeThumbnail from '@/images/landing-page/Sensor67/youtube/3.jpeg'
  import fourThumbnail from '@/images/landing-page/Sensor67/youtube/4.jpeg'
  import fiveThumbnail from '@/images/landing-page/Sensor67/youtube/5.jpeg'
  import sixThumbnail from '@/images/landing-page/Sensor67/youtube/6.jpeg'
  import sevenThumbnail from '@/images/landing-page/Sensor67/youtube/7.jpeg'
  import eightThumbnail from '@/images/landing-page/Sensor67/youtube/8.jpeg'
  import nineThumbnail from '@/images/landing-page/Sensor67/youtube/9.jpeg'
  import tenThumbnail from '@/images/landing-page/Sensor67/youtube/10.jpeg'
  import elevenThumbnail from '@/images/landing-page/Sensor67/youtube/11.jpeg'
  import twelveThumbnail from '@/images/landing-page/Sensor67/youtube/12.jpeg'
  import thirteenThumbnail from '@/images/landing-page/Sensor67/youtube/13.jpeg'
  import fourteenThumbnail from '@/images/landing-page/Sensor67/youtube/14.jpeg'
  import { CloseSharp } from '@/components/icons'

  import Carousel from '@/components/Carousel.svelte'
  import CarouselItem from '@/components/CarouselItem.svelte'
  import Modal from '../../Modal.svelte'

  const links = [
    {
      url: 'https://www.youtube.com/watch?v=nQ7v6PHpdqU',
      id: 'nQ7v6PHpdqU',
      image: oneThumbnail,
      title: 'Lauryn',
      time: '00:59',
    },
    {
      url: 'https://www.youtube.com/watch?v=vIpK3XYFCf0',
      id: 'vIpK3XYFCf0',
      image: twoThumbnail,
      title: 'Robert Horton Review',
      time: '00:44',
    },
    {
      url: 'https://www.youtube.com/watch?v=N51oEOmxbAs',
      id: 'N51oEOmxbAs',
      image: threeThumbnail,
      title: 'Alex Chaney Unboxing',
      time: '02:14',
    },
    {
      url: 'https://www.youtube.com/watch?v=zwIMo_G6LZY',
      id: 'zwIMo_G6LZY',
      image: fourThumbnail,
      title: 'Zak Review',
      time: '00:41',
    },
    {
      url: 'https://www.youtube.com/watch?v=UwEElz6tLZU',
      id: 'UwEElz6tLZU',
      image: fiveThumbnail,
      title: 'Rachel Stefursky Review',
      time: '00:33',
    },
    {
      url: 'https://www.youtube.com/watch?v=Zn5wRIG8LTI',
      id: 'Zn5wRIG8LTI',
      image: sixThumbnail,
      title: 'Savannah Sanchez Unboxing',
      time: '00:16',
    },
    {
      url: 'https://www.youtube.com/watch?v=EbdK4rGQly4',
      id: 'EbdK4rGQly4',
      image: sevenThumbnail,
      title: 'Savannah Sanchez Review',
      time: '00:16',
    },
    {
      url: 'https://www.youtube.com/watch?v=k5tlD-dFXjQ',
      id: 'k5tlD-dFXjQ',
      image: eightThumbnail,
      title: 'Kaite Review',
      time: '01:19',
    },
    {
      url: 'https://www.youtube.com/watch?v=fiGcNCiqFBE',
      id: 'fiGcNCiqFBE',
      image: nineThumbnail,
      title: 'James Review',
      time: '00:59',
    },
    {
      url: 'https://www.youtube.com/watch?v=wciISuaa3nQ',
      id: 'wciISuaa3nQ',
      image: tenThumbnail,
      title: 'Gwen Review',
      time: '02:01',
    },
    {
      url: 'https://www.youtube.com/watch?v=DJ-FaRXGuxE',
      id: 'DJ-FaRXGuxE',
      image: elevenThumbnail,
      title: 'Testimonial Mashup',
      time: '02:36',
    },
    {
      url: 'https://www.youtube.com/watch?v=Splow0yMEFo',
      id: 'Splow0yMEFo',
      image: twelveThumbnail,
      title: 'Expert Reviews',
      time: '02:12',
    },
    {
      url: 'https://www.youtube.com/watch?v=6E7efgDtrHc',
      id: '6E7efgDtrHc',
      image: thirteenThumbnail,
      title: 'CarThrottle FIXD Review',
      time: '01:00',
    },
    {
      url: 'https://www.youtube.com/watch?v=KyTdevENbYU',
      id: 'KyTdevENbYU',
      image: fourteenThumbnail,
      title: 'Premium UGC - Shawn',
      time: '01:11',
    },
  ]

  $: showModal = false
  $: currentYoutubeLink = links[0].id

  const handleClick = (link) => {
    currentYoutubeLink = link
    showModal = true
  }

  const handleClose = () => {
    showModal = false
    currentYoutubeLink = null
  }
</script>

<style>
  .thumbnail img {
    transition: all 250ms;
  }

  .thumbnail:hover img {
    transform: scale(1.1);
  }

  .thumbnail .overlay {
    transition: all 250ms;
    opacity: 0;
  }

  .thumbnail:hover .overlay {
    opacity: 0.9;
  }
</style>

<Modal bind:showModal maxWidth="lg" onClose={handleClose}>
  <div class="flex bg-transparent flex-col md:flex-row">
    <div class="relative pb-[56.25%] w-full h-[0px]">
      <iframe
        class="absolute top-0 left-0 w-full h-full"
        src="https://www.youtube.com/embed/{currentYoutubeLink}"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    </div>
    <div class="mt-2 md:ml-2 md:mt-0">
      <CloseSharp
        on:click={handleClose}
        size="md"
        color="grey"
        class="cursor-pointer"
      />
    </div>
  </div>
</Modal>

<div class="bg-white rounded md:rounded-2xl p-2 md:p-5">
  <Carousel
    showPagination={false}
    showArrows={true}
    loop={false}
    splideOptions={{
      perPage: 3,
      gap: 20,
      lazyLoad: true,
      breakpoints: {
        768: {
          gap: 8,
        },
      },
    }}
  >
    {#each links as item}
      <CarouselItem>
        <div
          class="group relative cursor-pointer thumbnail overflow-hidden"
          on:click={() => handleClick(item.id)}
          on:keydown={() => handleClick(item.id)}
          role="button"
          tabindex="-1"
        >
          <img
            src={item.image}
            alt=""
            class="md:h-[160px] w-full object-cover"
            lazy
          />
          <div
            class="opacity-0 overlay w-full h-full bg-green absolute top-0 left-0"
          >
            <div class="text-left p-3">
              <p class="text-white font-semibold text-sm">{item.title}</p>
            </div>
          </div>
          <div class="absolute bg-green bottom-2 right-2 px-1 py-0.5 rounded">
            <p class="text-white text-xs">{item.time}</p>
          </div>
        </div>
      </CarouselItem>
    {/each}
  </Carousel>
</div>
