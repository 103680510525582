<script>
  import { twMerge } from 'tailwind-merge'
  import { colors, baseSize, sizes, baseStyle } from './base-settings.js'

  export let size = baseSize
  export let role = 'img'
  export let color = 'currentColor'

  export let ariaLabel = 'Sales Tag icon'

  $: fillColor = colors[color] || color
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  fill={fillColor}
  {...$$restProps}
  class={twMerge(baseStyle, sizes[size ?? 'md'], $$props.class)}
  {role}
  aria-label={ariaLabel}
  tabindex="-1"
  viewBox="0 0 30 24"
  on:click
  on:keydown
  on:keyup
  on:focus
  on:blur
  on:mouseenter
  on:mouseleave
  on:mouseover
  on:mouseout
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <path
    d="M23.341 10.591L13.409 0.659015C12.9871 0.237058 12.4148 3.12036e-06 11.818 0L2.25 0C1.00734 0 0 1.00734 0 2.25V11.818C3.12036e-06 12.4148 0.237058 12.9871 0.659015 13.409L10.591 23.341C11.4696 24.2197 12.8942 24.2197 13.7729 23.341L23.341 13.773C24.2196 12.8943 24.2196 11.4697 23.341 10.591ZM5.25 7.5C4.00734 7.5 3 6.49265 3 5.25C3 4.00734 4.00734 3 5.25 3C6.49265 3 7.5 4.00734 7.5 5.25C7.5 6.49265 6.49265 7.5 5.25 7.5ZM29.341 13.773L19.7729 23.341C18.8943 24.2197 17.4697 24.2197 16.591 23.341L16.5741 23.3241L24.7331 15.1651C25.5299 14.3683 25.9687 13.3088 25.9687 12.182C25.9687 11.0551 25.5299 9.99567 24.7331 9.19884L15.5342 0H17.818C18.4148 3.12036e-06 18.9871 0.237058 19.409 0.659015L29.341 10.591C30.2196 11.4697 30.2196 12.8943 29.341 13.773Z"
    fill={fillColor}
  />
</svg>
