<script>
  import Section from './widgets/Section.svelte'
  import BenefitsContent from './widgets/BenefitsContent.svelte'
</script>

<Section center="true">
  <slot name="header">
    <div class="mb-7">
      <h2 class="text-30">
        Here's why FIXD is more than just another code reader
      </h2>
    </div>
  </slot>

  <p class="mb-4">
    FIXD not only gives you access to detailed engine information you can use to
    be more informed at the mechanic, but it also includes powerful features to
    help you save money and avoid costly repairs and breakdowns…
  </p>

  <BenefitsContent />

  <slot name="cta" />
</Section>
