<script>
  import SocialMediaLinks from '@/components/SocialMediaLinks.svelte'
  import Section from './widgets/Section.svelte'

  import {
    supportURL,
    careersURL,
    privacyPolicyURL,
    refundPolicyURL,
  } from '@/util/links'
</script>

<Section center="true" verticalPadding="short" background="green">
  <SocialMediaLinks />

  <div class="my-4">
    <p>
      Copyright &copy; {new Date().getFullYear()}, FIXD Automotive. All Rights
      Reserved. Made with love in Atlanta.
    </p>
  </div>

  <div class="flex space-x-6 justify-center text-sm flex-wrap">
    <a class="text-white" href={supportURL}>Contact Us</a>
    <a class="text-white" href={careersURL}>Careers</a>
    <a class="text-white" href={refundPolicyURL}>Returns & Warranty</a>
    <a class="text-white" href={privacyPolicyURL}>Privacy</a>
  </div>
</Section>
