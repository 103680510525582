<script>
  import { twMerge } from 'tailwind-merge'
  import { colors, baseSize, sizes, baseStyle } from './base-settings.js'

  export let size = baseSize
  export let role = 'img'
  export let color = 'currentColor'

  export let ariaLabel = 'amazon store solid'

  $: fillColor = colors[color] || color
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  fill={fillColor}
  {...$$restProps}
  class={twMerge(baseStyle, sizes[size ?? 'md'], $$props.class)}
  {role}
  tabindex="-1"
  aria-label={ariaLabel}
  viewBox="0 0 24 24"
  on:click
  on:keydown
  on:keyup
  on:focus
  on:blur
  on:mouseenter
  on:mouseleave
  on:mouseover
  on:mouseout
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <path
    fill-rule="evenodd"
    d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2ZM6.182 14.942c-.121-.058-.243.057-.121.172 1.513 1.294 3.48 2.128 5.689 2.128 1.543 0 3.45-.546 4.721-1.438.212-.143.03-.373-.181-.316-1.423.575-3.178.863-4.57.863-2.058 0-3.904-.518-5.538-1.41Zm11.257-.144c-.12-.172-1.3-.316-1.997.173-.12.086-.09.172.03.172.394-.029 1.302-.144 1.453.058.151.2-.182 1.006-.333 1.35-.06.087.06.145.151.058.666-.517.818-1.638.697-1.81ZM11.901 6c-1.664 0-3.117.633-3.48 2.415-.03.201.121.288.242.316l1.544.173c.15 0 .242-.144.272-.288.121-.604.636-.891 1.24-.891.334 0 .97.173.97.863v.862c-.97 0-1.998 0-2.846.345-.998.402-1.694 1.236-1.694 2.473 0 1.58 1.029 2.357 2.36 2.357 1.12 0 1.786-.259 2.663-1.092.273.402.394.632.908 1.063a.363.363 0 0 0 .302.013l.061-.041c.303-.26.878-.748 1.21-1.007.152-.115.122-.287 0-.431-.302-.374-.604-.69-.604-1.409V9.335c0-1.006 0-1.898-.757-2.588-.605-.546-1.604-.747-2.39-.747Zm.757 4.6v.316c0 .575-.03 1.984-1.513 1.984-.908 0-.908-.863-.908-.863 0-1.293 1.27-1.437 2.42-1.437Z"
    clip-rule="evenodd"
  ></path>
</svg>
