<script>
  import reasonFreeShippingUrl from '@/images/landing-page/fast-shipping-white.png'
  import reasonReturnPolicyUrl from '@/images/landing-page/hand-holding-dollar-icon-white.png'
  import reasonWarrantyUrl from '@/images/landing-page/shield-icon-white.png'
  import Section from './widgets/Section.svelte'

  export let percentage = 67
</script>

<Section center="true" background="dark">
  <div class="try-risk-free">
    <div class="mb-7">
      <h1 class="text-white text-30">
        Try FIXD Risk-Free and Save {percentage}%!
      </h1>
    </div>

    <div class="flex flex-col md:flex-row mb-7">
      <div class="text-center mx-6 my-1 md:m-2.5">
        <img
          class="size-12"
          src={reasonFreeShippingUrl}
          alt="Free Shipping"
          loading="lazy"
        />
        <p class="text-22 text-white mt-2">
          Free Domestic Shipping on all U.S. Orders
        </p>
      </div>

      <div class="text-center mx-6 my-1 md:mx-2.5 md:my-2.5">
        <img
          class="size-12"
          src={reasonWarrantyUrl}
          alt="Warranty"
          loading="lazy"
        />
        <p class="text-22 text-white mt-2">
          30-Day Risk-Free Money Back Guarantee
        </p>
      </div>

      <div class="text-center mx-6 my-1 md:m-2.5">
        <img
          class="size-12"
          src={reasonReturnPolicyUrl}
          alt="Return Policy"
          loading="lazy"
        />
        <p class="text-22 text-white mt-2">
          1 Year Limited Warranty on FIXD Sensors
        </p>
      </div>
    </div>
    <div class="trf-container">
      <slot name="cta" />
    </div>
  </div>
</Section>
